<template>
  <div class="login">
    <div class="content-box">
      <div class="login-left-img">
        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/new-saas/PC/login/left-img1.png" alt="">
        <img class="left-logo" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/new-saas/PC/login/left-logo.png" alt="">
      </div>
      <div class="login-right-form">
        <ul class="tabs">
          <li :key="item.key" v-for="(item) in tabsList" @click="setActiveName(item.key)" class="tabItem"
            :class="{ active: activeName === item.key }">{{ item.val }}</li>
        </ul>
        <el-form class="form" ref="form" :rules="rules" :model="formData">
          <el-form-item prop="account" ref="account">
            <el-input placeholder="请输入手机号" autofocus v-model="formData.account" @keyup.enter.native="onSubmit" key="account"
              clearable></el-input>
          </el-form-item>
          <el-form-item prop="password" v-if="activeName === 'password'" class="passwordItem" ref="password">
            <el-input :type="pass" placeholder="请输入密码" v-model="formData.password" key="password" clearable>
              <template slot="append">
                <span @click="handleshowPsd()" class="pwd-icon">
                  <svg-icon icon-class="yanjing-2" v-if="pass == 'text'"></svg-icon>
                  <svg-icon v-else icon-class="yanjing-1"></svg-icon>
                </span>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item v-else prop="authCode" class="authCode" ref="authCode">
            <el-input placeholder="验证码" v-model="formData.authCode" @keyup.enter.native="onSubmit">
              <el-button slot="append" @click="getAuthCode" round>{{ 0 >= Countdown ? '获取验证码' : Countdown + 's'
              }}</el-button>
            </el-input>

          </el-form-item>
        </el-form>
        <div class="bottom">
          <div class="read">
            <el-checkbox v-model="hasreaded">
              <span>我同意</span>
              <span class="link" @click.stop="goPage(1)">《用户协议》</span>
              <span>和</span>
              <span class="link"  @click.stop="goPage(2)">《法律声明及隐私政策》</span>
            </el-checkbox>
          </div>
          <p class="loginBtn" @click="onSubmit">登录</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import md5 from "js-md5";
import { phoneValidator, isPhone } from '@/utils/validator'
export default {
  data() {
    return {
      privacyURL:'https://wd-static-page.wdeduc.com/wd-jj-agreement/privacy.html',
      agreementURL:'https://wd-static-page.wdeduc.com/wd-jj-agreement/service-agreement.html',
      pass: 'password',
      hasreaded: false,
      CountdownInterval: null,
      Countdown: 0,
      activeName: 'Verification',
      tabsList: [
        { key: "Verification", val: "手机号登录" },
        { key: "password", val: "密码登录" },
      ],
      formData: {
        account: null,
        password: null,
        authCode: null
      },
      rules: {
        account: [
          {
            validator: phoneValidator,
            trigger: "blur"
          }
        ],
        password: [
          { required: true, message: "请输入登录密码", trigger: "blur" }
        ],
        authCode: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          { min: 6, max: 6, message: "请输入六位验证码", trigger: "blur" }
        ]
      },
    }
  },
  computed: {
  },
  created(){
    this.getConfiguration()
    sessionStorage.removeItem('index')
  },
  methods: {
    //   隐私协议跳转
    goPage(type){
        let URL = type === 1 ? this.agreementURL  : this.privacyURL;
        window.open(URL)
    },
    handleshowPsd() {
      if (this.pass == 'password') {
        this.pass = 'text'
      } else {
        this.pass = 'password'
      }
    },
    // tab 切换
    setActiveName(val) {
      if (val === this.activeName) return
      this.activeName = val;
      this.$refs.account.clearValidate();
      if (this.activeName === "password") {
        this.formData.authCode = '';
        this.$refs.authCode.clearValidate();
      } else {
        this.formData.password = '';
        this.$refs.password.clearValidate();
      }
    },
    setCountdown() {
      this.CountdownInterval = setInterval(() => {
        this.Countdown--;
        if (this.Countdown <= 0) {
          clearInterval(this.CountdownInterval);
        }
      }, 1000);
    },
    // 获取验证码
    async getAuthCode() {
      const data = { account: this.formData.account, type: 1 };
      this.formData.authCode = null;
      if (!this.formData.account) {
        this.$message.warning('请填写手机号');
        return false;
      }
      if (!isPhone.test(this.formData.account)) {
        this.$message.warning('请填写正确的手机号');
        return false;
      }
      if (!this.hasreaded) {
        this.$message.warning('请先阅读并同意协议');
        return false;
      }
      if (this.Countdown > 0) return;
      this.Countdown = 60;
      this.setCountdown();
      this.$sensors.track('UM_Click_Verification_Code_Button')
      this.$api.usercenter.postAccountGetAuthCode({ data })
    },
    // 登录
    async onSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (!this.hasreaded) {
            this.$message.warning('请勾选用户协议')
            return false;
          }
          this.toLogin();
        } else {
          return false;
        }
      });
    },
    async getUserBranchList() {
      const res = await this.$api.usercenter.getFindUserBranchList({});
      if (res.data && res.data.length) {
        localStorage.setItem('UserBranchList', JSON.stringify(res.data))
        await this.switchUserBranch(res.data[0].ucode)
        this.$sensors.login(res.data[0].code)
      }
    },
    async switchUserBranch(ucode) {
      const params = {
        ucode
      }
      const res = await this.$api.usercenter.switchUserBranch({ params });
      if (res.success) {
        await this.getUserInfo()
        await this.getUserInfoSecurity();
        if (this.$route.query.name) {
          this.$router.push({
              path: this.$route.query.name + window.location.search
          })
        }else{
          this.$router.push('/')
        }
      }
    },
    async getConfiguration() {
      await this.$api.configuration.getConfiguration().then(res => {
        if (res.code === 0 && res.data) {
          let frontendConfig = res.data.frontendConfig.filter(item => {
            if (item.key === 'homepage') {
              return true;
            }
          })[0];
          console.log(res.data.solutionInfo,'res.data.solutionInfo')
          localStorage.setItem('domain', res.data.solutionInfo.domain?JSON.stringify(res.data.solutionInfo.domain):'');
          localStorage.setItem('theme', res.data.theme || '');
          localStorage.setItem('frontendConfig', frontendConfig ? JSON.stringify(frontendConfig) : '');
          localStorage.setItem('configurationArr', JSON.stringify(res.data.frontendConfig))
          localStorage.setItem('setTempConfigs', JSON.stringify(res.data)) 
          sessionStorage.setItem('branchId',res.data.branchId)
          sessionStorage.setItem('saasName',res.data.solutionName)
          sessionStorage.setItem('saasId',res.data.solutionInfo.rid)
          if (res.data.websiteSetting && res.data.websiteSetting.pageLogo) {
            let link = document.querySelector("link[rel*='icon']");
            link.href = res.data.websiteSetting.pageLogo;
            localStorage.setItem('pageLogo', res.data.pageLogo);
          }
          if (res.data.websiteSetting && res.data.websiteSetting.pageTitle) {
            document.title = res.data.websiteSetting.pageTitle;
            localStorage.setItem('pageTitle', res.data.pageTitle)
          }
        }
      })
    },
    // 获取个人资料 手机身份证加密
    async getUserInfoSecurity() {
      await this.$api.usercenter.getUserInfoSecurity({}).then(res => {
        if (res.data) {
          this.$store.commit('subUserInfo', Object.assign({},res.data))
          localStorage.setItem('subUserInfo', JSON.stringify(Object.assign({},res.data)))
        }
      })
    },
    // 获取个人资料 手机身份证不加密
    async getUserInfo() {
      await this.$api.usercenter.getUserInfo({}).then(res => {
        if (res.data) {
          this.$store.commit('userInfo', res.data)
          localStorage.setItem('userInfo', JSON.stringify(res.data))
        }
      })
    },

    async toLogin() {

      const data = { ...this.formData };
      data.password && (data.password = md5(data.password).toLowerCase())
      let method = ''
      if (this.activeName === 'password') {//密码登录
        method = 'postAccountLogin'
      } else {//验证码登录
        method = 'postAccountLoginByAuthCode'
      }
      await this.$api.usercenter[method]({ data })
        .then(async res => {
          if (res.data) {
            localStorage.setItem("token", res.data);
            try {
              this.getConfiguration();
              await this.getUserBranchList()
            } catch (error) {
              console.log(error, 'error----')
              if (error) {
                this.$message.error(error)
                return
              }
            }
          } else {
            return res.data;
          }
        })
        .catch(() => {
          return false;
        });
    }
  }
}
</script>

<style lang="scss" src="../asset/css/login.scss" scoped>

</style>